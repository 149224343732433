import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  RecruitWrap,
  RecruitInner,
  RecruitTitle,
  RecruitHeader,
  RequirementWrap,
  RequirementTitle,
  RequirementTable,
  RequirementFlow,
  RequirementFlowNote,
  RequirementButton,
} from "../../components/recruit/recruit-parts"

import RecruitSwitcher from "../../components/recruit/recruit-switcher"

const Recruit: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Recruit　求人募集"
        description="東京都八丈島への移住・定住をご希望の方、Ｕターンの方も大歓迎です。"
      />
      <RecruitSwitcher>
        <RecruitWrap>
          <RecruitInner>
            <RecruitTitle>Recruit</RecruitTitle>
            <RecruitHeader>【キャリア採用・第二新卒】募集要項</RecruitHeader>

            <RequirementWrap>
              <RequirementTitle>募集職種</RequirementTitle>
              <p>
                ・SDGsビジネスプロデューサー（総合職）
                <br />
                ・空間デザイナー（ランドスケープデザイン含む）
                <br />
                ・CADデザイナー／オペレータ
                <br />
                ・UI/UXデザイナー
              </p>
            </RequirementWrap>

            <RequirementWrap>
              <RequirementTitle>仕事内容</RequirementTitle>
              <p>
                ・企画書、提案書の作成
                <br />
                ・クライアントへのプレゼンテーション
                <br />
                ・UI/UX設計～資料作成
                <br />
                ・クリエイティブの監修
              </p>
            </RequirementWrap>

            <RequirementWrap>
              <RequirementTitle>雇用形態</RequirementTitle>
              <p>正社員</p>
            </RequirementWrap>

            <RequirementWrap>
              <RequirementTitle>給与</RequirementTitle>
              <p>
                <span className="strong">応相談</span>
                <br />
                ・年俸制/12分割、賞与無
                <br />
                ★試用期間6カ月
                <br />
                【想定年収】276万円～650万円程度（経験・能力など考慮）
                <br />
                【月収】230,000円～541,666円
                <br />
                【残業手当】月60時間該当分の固定残業代を月収に含んで支給（超過分は追加支給）
              </p>
            </RequirementWrap>

            <RequirementWrap>
              <RequirementTitle>条件など</RequirementTitle>
              <RequirementTable>
                <tr>
                  <th>勤務地</th>
                  <td>東京都八丈島八丈町三根1988-2</td>
                </tr>
                <tr>
                  <th>勤務時間</th>
                  <td>9:00～18:00（休憩60分）</td>
                </tr>
                <tr>
                  <th>休日・休暇</th>
                  <td>
                    土、日、祝日、特別休暇、年末年始休暇、慶弔休暇、創立記念日、育児・介護休業、介護休暇、結婚休暇、年次有給休暇（入社半年後10日、1年ごとに1日プラスで最大20日付与）
                  </td>
                </tr>
                <tr>
                  <th>福利厚生</th>
                  <td>各種保険、定期健康診断、保養所など</td>
                </tr>
                <tr>
                  <th>研修制度</th>
                  <td>
                    KANAMELグループ各種研修制度、セミナー受講プログラム（年4以上）
                  </td>
                </tr>
                <tr>
                  <th>賞与</th>
                  <td>無し</td>
                </tr>
                <tr>
                  <th>昇給</th>
                  <td>有り（年1回1月）</td>
                </tr>
                <tr>
                  <th>その他</th>
                  <td>裁量労働制</td>
                </tr>
              </RequirementTable>
            </RequirementWrap>

            <RequirementWrap>
              <RequirementTitle>エントリーから採用までの流れ</RequirementTitle>
              <RequirementFlow>
                <li>エントリー</li>
                <li>書類選考</li>
                <li>面接（Zoom）</li>
                <li>採用</li>
                <li>入居※</li>
              </RequirementFlow>
              <RequirementFlowNote>
                ※オフィスの隣にシェアハウス型の住居棟を備えています。
                八丈島での住居が見つかるまで最大6カ月滞在できます。
              </RequirementFlowNote>
            </RequirementWrap>

            <RequirementButton to="/recruit/form" state={{ jobType: "career" }}>
              応募する
            </RequirementButton>
          </RecruitInner>
        </RecruitWrap>
      </RecruitSwitcher>
    </Layout>
  )
}

export default Recruit
